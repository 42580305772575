import { useTranslation } from "react-i18next";
import React from "react";
import {
  MemberRoleEnum,
  PreferredPositionEnum,
  PremiumLevel,
} from "~/utils/api.interfaces.enums";

type Props = {
  type: PremiumLevel | MemberRoleEnum | PreferredPositionEnum | string;
  label?: string;
};

export function MingleBadges({ type, label }: Props) {
  const { t } = useTranslation("translation");

  return (
    <>
      {type === "boostLevelZero" && (
        <div className="badge text-xs  rounded-full bg-white/10 py-2 text-white border-transparent">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
          >
            <path
              fill="#974DDE"
              fillRule="evenodd"
              d="M10.342 1.857c.537-.54 1.439-.026 1.255.714l-1.09 4.382h3.056c.65 0 .991.778.551 1.26l-7.206 7.905c-.517.567-1.448.08-1.282-.672l1.122-5.073h-3.07c-.666 0-1-.81-.529-1.285l7.193-7.231Z"
              clipRule="evenodd"
            />
          </svg>
          {label}
        </div>
      )}
      {type === "boostLevelOne" && (
        <div className="badge text-xs rounded-full bg-black py-2 text-[#FAC99E] border-[#FAC99E]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
          >
            <path
              fill="#FAC99E"
              fillRule="evenodd"
              d="M10.342 1.857c.537-.54 1.439-.026 1.255.714l-1.09 4.382h3.056c.65 0 .991.778.551 1.26l-7.206 7.905c-.517.567-1.448.08-1.282-.672l1.122-5.073h-3.07c-.666 0-1-.81-.529-1.285l7.193-7.231Z"
              clipRule="evenodd"
            />
          </svg>
          {label}
        </div>
      )}
      {type === PremiumLevel.Pro && (
        <div className="badge bg-purple-100 dark:bg-purple-700 ml-auto text-purple-700  dark:text-purple-100 text-xs rounded-sm border-0 gap-1 badge-lg flex flex-row justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 7.67811C2 6.75136 2.75128 6.00008 3.67803 6.00008H12.322C13.2487 6.00008 14 6.75136 14 7.67811V13.6554C14 14.5821 13.2487 15.3334 12.322 15.3334H3.67803C2.75128 15.3334 2 14.5821 2 13.6554V7.67811ZM3.67803 7.33342C3.48766 7.33342 3.33333 7.48774 3.33333 7.67811V13.6554C3.33333 13.8458 3.48766 14.0001 3.67803 14.0001H12.322C12.5123 14.0001 12.6667 13.8458 12.6667 13.6554V7.67811C12.6667 7.48774 12.5123 7.33342 12.322 7.33342H3.67803Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 4.00008C4 2.15913 5.49238 0.666748 7.33333 0.666748H8.66667C10.5076 0.666748 12 2.15913 12 4.00008V6.66675C12 7.03494 11.7015 7.33342 11.3333 7.33342C10.9651 7.33342 10.6667 7.03494 10.6667 6.66675V4.00008C10.6667 2.89551 9.77124 2.00008 8.66667 2.00008H7.33333C6.22876 2.00008 5.33333 2.89551 5.33333 4.00008V6.66675C5.33333 7.03494 5.03486 7.33342 4.66667 7.33342C4.29848 7.33342 4 7.03494 4 6.66675V4.00008Z"
              fill="currentColor"
            />
            <path
              d="M7.99996 12.6667C8.73634 12.6667 9.33329 12.0697 9.33329 11.3333C9.33329 10.597 8.73634 10 7.99996 10C7.26358 10 6.66663 10.597 6.66663 11.3333C6.66663 12.0697 7.26358 12.6667 7.99996 12.6667Z"
              fill="#7E40B9"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.00004 8.66675C8.36823 8.66675 8.66671 8.96522 8.66671 9.33341L8.66671 11.3334C8.66671 11.7016 8.36823 12.0001 8.00004 12.0001C7.63185 12.0001 7.33337 11.7016 7.33337 11.3334L7.33337 9.33341C7.33337 8.96522 7.63185 8.66675 8.00004 8.66675Z"
              fill="currentColor"
            />
          </svg>
          Premium
        </div>
      )}
      {type === MemberRoleEnum.Player && (
        <div className="badge bg-gray-100 dark:bg-gray-700 text-gray-700  dark:text-gray-100 text-xs rounded-sm border-0 gap-1 badge-lg flex flex-row justify-center items-center">
          {MemberRoleEnum.Player}
        </div>
      )}
      {type === MemberRoleEnum.Support && (
        <div className="badge bg-gray-100 dark:bg-gray-700 text-gray-700  dark:text-gray-100 text-xs rounded-sm border-0 gap-1 badge-lg flex flex-row justify-center items-center">
          {MemberRoleEnum.Support}
        </div>
      )}
      {type === MemberRoleEnum.Trainer && (
        <div className="badge bg-gray-100 dark:bg-gray-700 text-gray-700  dark:text-gray-100 text-xs rounded-sm border-0 gap-1 badge-lg flex flex-row justify-center items-center">
          {MemberRoleEnum.Trainer}
        </div>
      )}
    </>
  );
}
